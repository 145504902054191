<template>
  <div class="addOrEdit">
    <h1>{{ !id ? '新增' : '编辑' }}藏品</h1>
    <el-form ref="ruleForm" :rules="rules" :model="form" label-width="120px">
      <el-form-item label="藏品名称" required prop="name">
        <el-input v-model="form.name" :maxlength="10" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品简介" required prop="desc">
        <el-input v-model="form.desc" :maxlength="10" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品描述" required prop="content">
        <el-input v-model="form.content" :maxlength="116" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品图片" required prop="imageUrl">
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :http-request="handleImageUpload"
          :before-upload="beforeAvatarUpload">
          <img v-if="form.imageUrl" :src="$host + form.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="藏品音频描述" required prop="fileList">
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :http-request="handleAudioUpload"
          :before-upload="beforeAudioUpload"
          :on-exceed="handleExceed"
          :file-list="form.fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传.mp3文件，且不超过100MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="btns">
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      form: {
        name: '',
        desc: '',
        content: '',
        imageUrl: '',
        fileList: []
      },
      rules: {
        name: [
          { required: true, message: '请输入藏品名称', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入藏品简介', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入藏品描述', trigger: 'blur' },
        ],
        imageUrl: [
          { required: true, message: '请上传藏品图片', trigger: 'blur' }
        ],
        fileList: [
          { required: true, message: '请上传藏品音频', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      this.$fetch({
        url: '/getCollectionInfo',
        method: 'post',
        data: {
          id: this.id
        }
      }).then((res) => {
        if (res.code == 200) {
          this.form.name = res.data.title
          this.form.desc = res.data.description
          this.form.content = res.data.content
          this.form.imageUrl = res.data.imgUrl
          this.form.fileList = [{
            name: res.data.audioUrl.split('/')[res.data.audioUrl.split('/').length - 1],
            url: this.$host + res.data.audioUrl
          }]
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            name: this.form.name,
            desc: this.form.desc,
            content: this.form.content,
            imageUrl: this.form.imageUrl,
            audioUrl: this.form.fileList[0].url.replace(this.$host, '')
          }
          let url = ''
          if (this.id) {
            url = '/updateCollection'
            data.id = this.id
          } else {
            url = '/addCollection'
          }
          this.$fetch({
            url,
            method: 'post',
            data
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success(`${this.id ? '更新' : '新增'}成功`)
              setTimeout(() => {
                window.history.go(-1)
              })
            } else {
              this.$message.error(res.msg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleImageUpload(file) {
      let params = new FormData()
      params.append('file', file.file)
      params.append('type', 'images')
      this.$fetch({
        url: '/upload',
        method: 'post',
        data: params
      }).then((res) => {
        if (res.code == 200) {
          this.form.imageUrl = res.url
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAudioUpload(file) {
      let params = new FormData()
      params.append('file', file.file)
      params.append('type', 'audio')
      this.$fetch({
        url: '/upload',
        method: 'post',
        data: params
      }).then((res) => {
        if (res.code == 200) {
          this.form.fileList = [{name: file.file.name, url: this.$host+res.url}]
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    beforeAudioUpload(file) {
      const type = file.name.split('.')[file.name.split('.').length - 1]
      if (type != 'mp3') {
        this.$message.error('文件类型只能为mp3格式')
        return false
      }
      return true
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url)
    },
    handleExceed(files, fileList) {
      this.$message.warning('只能上次一个文件');
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  }
}
</script>

<style lang="less" scoped>
  .addOrEdit {
    padding: 30px;

    h1 {
      text-align: center;
      padding-bottom: 10px;
    }
    .btns {
      text-align: center;
    }
    ::v-deep .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    ::v-deep .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
</style>